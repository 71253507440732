import React, { useContext, useEffect, useState } from "react";
import blueTick from "../../../Images/blue-tick.svg";
import Modal from "./Modal";
import request from "../../../axios";
import { UserContextProvider } from "../../../Context/UserContext";
const Address = () => {
  const [showModal, setShowModal] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [refectchAddress, setRefectchAddress] = useState(true);
  const [modalAddress, setModalAddress] = useState({});
  const { user, setUser } = useContext(UserContextProvider);

  const closeModal = () => {
    setModalAddress({});
    setShowModal(false);
  };

  const openModal = () => {
    setModalAddress({});
    setShowModal(true);
  };

  useEffect(() => {
    if (user && user.id && refectchAddress) {
      request
        .get(`/list_addresses?user_id=${user.id}`)
        .then((res) => {
          console.log(res.data.data.list);

          if (res && res.data && res.data.data && res.data.data.list) {
            setAddresses(res.data.data.list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setRefectchAddress(false);
    }
    // setAddresses(addressList);
  }, [user, refectchAddress]);

  return (
    <div className="col-md-9 full-sec">
      <div>
        <p class="mb-0 pg-hd">My Addresses</p>
        <small class="small-txt">{addresses.length || 0} addresses</small>
        <br />
        <div
          class="card mt-3"
          style={{
            border: "3px solid #e9ecef",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              padding: "20px",
              borderBottom: "1px solid #e9ecef",
            }}
          >
            <p>Your Shipping Address</p>
            <button class="btn tab1-btn" type="button" onClick={openModal}>
              <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;{" "}
              <span>Add New Address</span>
            </button>
          </div>
          <div class="card-body">
            <div class="row add-para">
              {addresses.length <= 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bolder",
                    fontSize: "40px",
                  }}
                >
                  No Address{" "}
                </div>
              )}
              {addresses.map((address, index) => {
                return (
                  <div class="col-md-6">
                    <div>
                      <ul
                        class="list-inline address-head"
                        style={{ display: "flex", justifyContent: "start" }}
                      >
                        <li class="list-inline-item">
                          <img src={blueTick} alt="" />
                        </li>
                        <li class="list-inline-item">
                          <span>Address {index + 1}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="cust-scroller">
                      <p id="edit">
                        <b>
                          <span>{address.name}</span>
                          <br />
                          <span>{address.flat}</span>
                          <br />
                          <span>
                            {address.area} {address.landmark}
                          </span>
                          <br />
                          <span>
                            {address.city} {address.state} {address.pincode}
                          </span>
                          <br />
                          <span>{address.phone_number}</span>
                        </b>
                      </p>

                      <div class="btn-line">
                        <a
                          href="##"
                          onClick={() => {
                            setModalAddress(address);
                            setShowModal(true);
                          }}
                        >
                          Edit Address
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalAddress={modalAddress}
        closeModal={closeModal}
        setRefectchAddress={setRefectchAddress}
        user={user}
      />
    </div>
  );
};

export default Address;
