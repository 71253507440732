import React from "react";
import Button from "react-bootstrap/Button";
import Logo from "../../Images/Logo.svg";
import Banner from "../../Images/Banner.svg";
import Right from "../../Images/right.png";
import Mobile from "../../Images/section2-mobile.svg";
import ColorFulBtn from "../../Images/colorful-btn.svg";
import Section3Image1 from "../../Images/section 3 image1.svg";
import Section3Image2 from "../../Images/section 3 image2.svg";
import Section3Image3 from "../../Images/section 3 image3.svg";
import "./style.css";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Footer from "./Footer";
import Section6 from "./Section6";
import { Link } from "react-router-dom";

import Section51 from "./Section51";
import Section52 from "./Section52";
import AboutUs from "./AboutUs";

function Home() {
  return (
    <div>
      <div
        className="header homepageImg"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <div className="banner-img">
          <div className="top-assets">
            <div className="header-assets">
              <img src={Logo} alt="logo" />
              <div className="user-in-btn signupButton">
                <Link to="/auth">
                  <Button variant="link" className=" ">
                    Sign Up
                  </Button>
                </Link>
                <Link to="/auth">
                  <Button
                    size="lg"
                    className="btn form-control theme-btn loginButton"
                  >
                    Log In
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container banner-container">
            <h1 className="banner-heading">
              Share Your <span className="highlited-txt">Profile Details</span>{" "}
              With Just A Tap{" "}
            </h1>
            <div class="d-flex banner-btn">
              <div class="btn-mr">
                <Link to="/auth">
                  <button type="button" class="btn form-control theme-btn">
                    Get Started
                  </button>
                </Link>
              </div>
              <div>
                <Link to="https://wa.me/7982360446" target="_blank">
                  <button type="button" class="btn form-control black-btn">
                    Contact Us <img src={Right} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <img src={Mobile} alt="mobile" className="img-fluid" />
            </div>
            <div className="col-md-5">
              <div className="sec2-margin">
                <h4>How it works</h4>
                <h2 className="font40 lineheight70 pt-5">
                  {" "}
                  Customize Your Card <br></br>
                  <span className="highlited-txt">Create Your Profile</span>
                  <br></br> Share by Tapping
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid sec3-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="sec3-head">
                  <h1>
                    {" "}
                    One Card{" "}
                    <span className="highlited-txt-green">
                      Limitless Possibilities
                    </span>
                  </h1>
                  <p className="unlimited-txt pt-2">
                    {" "}
                    Add unlimited profiles. Tap your card unlimited times.{" "}
                  </p>
                  <img src={ColorFulBtn} alt="button" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-5">
                <div className="text-center">
                  <img
                    src={Section3Image1}
                    alt="image1"
                    className="img-fluid"
                  />
                </div>
                <div className="user-bx">
                  <img
                    src={Section3Image2}
                    alt="image2"
                    className="img-fluid"
                  />
                  <img
                    src={Section3Image3}
                    alt="image3"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Section3 />
      <Section4 />
      {/* <Section5 /> */}
      <Section51 />
      <Section52 />
      <AboutUs />
      <Section6 />
      <Footer />
    </div>
  );
}

export default Home;
