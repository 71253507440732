import React, { useContext, useState } from "react";
import request from "../../../../../../axios";
import { toast } from "react-toastify";
import { UserContextProvider } from "../../../../../../Context/UserContext";

const OrderDetails = ({ orderData, setOrderData }) => {
  const [orderStatus, setOrderStatus] = useState("");
  const { user, setUser } = useContext(UserContextProvider);

  const handleChangeOrderStatus = (e) => {
    setOrderStatus(e.target.value);
  };

  const handleSaveOrderStatus = () => {
    // call api

    let data = {
      order_id: orderData?.orderDetails?.id,
      order_status: orderStatus,
      user_id: user?.id,
    };

    request
      .post("/update_order", data)
      .then((res) => {
        setOrderData((prev) => {
          return {
            ...prev,
            orderDetails: {
              ...prev.orderDetails,
              order_status: orderStatus,
            },
          };
        });
        toast.success("Order status changed");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div class="row">
          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Order Id</label>
              <input
                type="text"
                name="name"
                readOnly
                value={orderData?.orderDetails?.id}
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Order Amount</label>
              <input
                type="text"
                name="sub_title"
                value={orderData?.orderDetails?.order_amount}
                readOnly
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <div>
                <label htmlFor="shipmentMethod">Order Status</label>
              </div>

              <select
                className="dropdown selectDropdown filled"
                name="shipmentMethod"
                value={orderStatus || orderData?.orderDetails?.order_status}
                onChange={handleChangeOrderStatus}
              >
                <option value="" disabled>
                  Select order status
                </option>
                <option value="order_cancelled">Order Cancelled</option>
                <option value="order_delivered">Order Delivered</option>
                <option value="order_in_transit">Order in transit</option>
                <option value="order_payment_due">order payment due</option>
                <option value="order_pickup_available">
                  Order pickup available
                </option>
                <option value="order_problem">Order problem</option>
                <option value="order_processing">Order processing</option>
                <option value="order_returned">Order returned</option>
              </select>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button
            disabled={orderStatus === ""}
            type="submit"
            class="btn btn-next btns"
            onClick={handleSaveOrderStatus}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
