import React, { useEffect, useState } from "react";

const Modal = ({ showModal, closeModal, addNewHandle, handleList }) => {
  const [selectedHandleId, setSelectedHandleId] = useState(() => {
    return handleList[1]?.id || "";
  });

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedHandleId(value);
  };

  return (
    <div>
      {showModal && (
        <div className="addressAdd">
          <div className="modelContent model-content">
            <div className="modelContent1" style={{ width: "30%" }}>
              <div class="modal-header">
                <h5 class="modal-title">Add Details</h5>
                <div style={{ cursor: "pointer" }} onClick={closeModal}>
                  <span>X</span>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div class="modal-body">
                  <div id="appenddiv">
                    <div class="row">
                      <div class="col-md-12">
                        <div
                          class="form-group"
                          style={{ marginTop: "1rem", cursor: "pointer" }}
                        >
                          <select
                            class="form-control"
                            value={selectedHandleId}
                            onChange={(e) => setSelectedHandleId(e.target.value)}
                          >
                            {handleList.filter(item => item.id !== "about_yourself").map((handles) => {
                              return (
                                <>
                                    <option value={handles.id}>
                                      {`${handles.handle_name
                                        .replace(/_/g, " ")
                                        .replace(/\b\w/g, (char) =>
                                          char.toUpperCase()
                                        )}`}
                                    </option>
                                </>
                              );
                            })}
                            {/* <option>Phone Number</option>
                            <option>Select Your Location</option>
                            <option>Website</option>
                            <option>Facebook Account</option>
                            <option>Twitter Account</option>
                            <option>Instagram Account</option>
                            <option>LinkedIn Account</option>
                            <option>EMail ID</option>
                            <option>Youtube Account</option>
                            <option>Telegram Account</option>
                            <option>Whatsapp Number</option>
                            <option>Snapchat Account</option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-footer text-center"
                  style={{
                    margin: "0 auto",
                    borderTop: "none !important",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => {
                      addNewHandle(selectedHandleId);
                    }}
                    style={{ width: "100px" }}
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
