import React, { useState } from "react";
import "../style.css";

const faqs = [
  {
    question: "Alright, but what exactly do you do?",
    answer: `At SnapTap, we provide NFC-enabled contact cards that allow individuals and businesses to share information, connect with others, and access online resources. 
Our cards are designed to be tapped on a phone, which opens a link on a browser, allowing users to access relevant information, such as websites, social media profiles, or contact information.
User will have a unique profile which can be accessed worldwide.
`,
  },
  {
    question: "I don't need a card, how will this help me?",
    answer: (
      <div
        style={{
          height: "400px",
        }}
      >
        <div>
          Our NFC-enabled contact cards can benefit individuals and businesses
          in several ways:
        </div>
        <br></br>
        <div>
          Businesses: Our cards can help you promote your products or services,
          connect with customers, and share information about your company.
          <br></br>
          <br></br>
          Individuals: Our cards can help you network more effectively, share
          your contact information with others, and access online resources
          easily.
          <br></br>
          <br></br>
          Anyone: Our cards can be used to share information about events,
          products, or services, making it easy for others to access the
          information they need.
          <br></br>
          <br></br>
          Influencers : Our cards can be used to share the links for the
          instagram profiles, youtube channels and many more options.
          <br></br>
          Some examples of how our cards can be used include:
        </div>
        <ul>
          <li>
            A business owner sharing their contact information and website with
            customers
          </li>
          <li>
            A musician sharing their social media profiles and music links with
            fans
          </li>
          <li>
            A event organizer sharing event details and registration links with
            attendees
          </li>
          <li>
            A product manufacturer sharing product information and purchasing
            links with customers
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: "Are your rates competitive?",
    answer: `Yes, we believe our rates are competitive. We strive to provide the best value for our customers by offering high-quality products and services at affordable prices. We also offer flexible pricing plans to fit your specific needs and budget. For the bulk order we can offer discounts.`,
  },
  {
    question: "Why do you have a one-time fee?",
    answer: (
      <div>
        <div>
          Our one-time fee is designed to cover the cost of producing and
          shipping the NFC-enabled contact card. We believe that our product is
          a valuable tool that will benefit you in the long run, and the
          one-time fee is a fair price for the benefits you receive.
        </div>
        <br></br>
        <div>
          In addition, our one-time fee allows us to focus on providing
          exceptional customer service and support, rather than relying on
          ongoing subscription fees. We believe that this approach allows us to
          build stronger relationships with our customers and provide a better
          overall experience.
        </div>
      </div>
    ),
  },
];

function Section6() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section class="mb-4 pb-4" style={{ marginTop: "0px" }} id="faq">
      <div class="container-fluid sec3-bg sec4-pd">
        <div class="container">
          <div class="text-center pb-5">
            <h1> Frequently Asked Questions </h1>
            <p class="sec4-para">
              {" "}
              Compatible with most phones. No app required{" "}
            </p>
          </div>

          <div className="accordion" id="faqAccordion">
            {faqs.map((faq, index) => (
              <div key={index} className="accordion-item">
                <button
                  id={`accordion-button-${index}`}
                  aria-expanded={expandedIndex === index}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="accordion-title">
                    <span className="faq-sequence">
                      {index + 1 < 10 ? "0" : ""}
                      {index + 1}
                    </span>
                    {faq.question}
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>

                <div className="accordion-content">
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section6;
