import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Button, Nav, Tab } from "react-bootstrap";
import background from "../../Images/background.svg";
import eyeClose from "../../Images/eye_close.svg";
import eyeOpen from "../../Images/eye_open.svg";

import logoSvg from "../../Images/Logo.svg";
import googleSvg from "../../Images/google.svg";
import facebookSvg from "../../Images/Facebook.svg";
import appleSvg from "../../Images/apple.svg";
import { Link, useNavigate } from "react-router-dom";
import request from "../../axios";
import { UserContextProvider } from "../../Context/UserContext";
import { toast } from "react-toastify";
import { updateLocalStorage } from "../../utils";
import TabContent from "../Home/Section3/TabContent";
const Auth = (props) => {
  const navigate = useNavigate();
  const redirectUrl = process.env.REACT_APP_API_BASE_URL;

  const { user, setUser } = useContext(UserContextProvider);
  const [otpMode, setOtpMode] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [isUserExist, setIsUserExist] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    phone_number: "",
    password: "",
    otp: "",
  });

  useEffect(() => {
    if (user && user.id) {
      if (user.isAdmin) {
        navigate("/admin/dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setLoginDetails((details) => {
      return {
        ...details,
        [name]: value,
      };
    });
  };

  const handleSendOtp = (e) => {
    if (loginDetails.phone_number) {
      request
        .get(`/send_otp?phone_number=${loginDetails.phone_number}`)
        .then((res) => {
          if (res?.data?.id) {
            setOtpSent(true);
            toast.success("OTP Sent successfully");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleAuthSubmit = async (e) => {
    let data = {};
    let url = "";
    if (!otpMode) {
      data.email = loginDetails.email;
      data.password = loginDetails.password;
      url = "/login_user";
    } else {
      data.phone_number = loginDetails.phone_number;
      data.otp = loginDetails.otp;
      url = "/verify_otp";
    }

    if ((data.email && data.password) || (data.phone_number && data.otp))
      await request
        .get(url, { params: data })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Logged in Successfully");
          }
          if (res && res.data && res.data.id) {
            setUser({
              ...data,
              id: res.data.id,
            });
            updateLocalStorage(res.data.id);

            if (res.data.is_admin) {
              navigate("/admin/dashboard");
            } else {
              navigate("/dashboard");
            }
          }
        })
        .catch((err) => {
          console.log("Create user error ", err);
        });
  };

  const checkEmail = async (e) => {
    let data = { email: loginDetails.email };

    if (data.email) {
      await request
        .get("/get_by_email", { params: data })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            if (res?.data?.is_user) {
              setIsUserExist(true);
            } else {
              setIsUserExist(false);
            }
          }
        })
        .catch((err) => {
          console.log("Create user error ", err);
        });
    }
  };

  return (
    <header>
      <div
        className="dashboard-bg"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="top-assets">
          <ul className="list-inline header-assets">
            <li className="list-inline-item">
              <Link to="/">
                <img src={logoSvg} alt="logo" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="main">
          <div className="d-flex justify-content-center">
            <div className="">
              <p className="mb-0">
                Welcome to <span className="blue">SnapTap</span>
              </p>
            </div>
            <div className=" text-right"></div>
          </div>
          <div className="row mt-4">
            <div>
              <a href={`${redirectUrl}/auth/google/callback`}>
                <div className="google-btn">
                  <img src={googleSvg} alt="googleLogo" /> &nbsp;&nbsp;Sign in
                  with Google
                </div>
              </a>
            </div>
          </div>
          <div
            className="mt-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ul className="list-inline toggle-section">
              <li className="list-inline-item">
                <label class="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setOtpMode((email) => !email);
                      setOtpSent(false);
                    }}
                    checked={otpMode}
                  />
                  <span class="slider round"></span>
                </label>
              </li>
              <li className="list-inline-item">Sign In with OTP</li>
            </ul>
          </div>
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {!otpMode && (
                <>
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="mt-2 mb-2">
                      Enter your Email
                    </label>
                    <input
                      type="email"
                      required
                      name="email"
                      className="form-control log-input"
                      placeholder="Email"
                      onChange={handleInputChange}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        borderRadius: "8px",
                      }}
                      onBlur={checkEmail}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="password" className="mt-2 mb-2">
                      Enter your Password
                    </label>
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                      }}
                    >
                      <input
                        type={`${showPassword ? "text" : "password"}`}
                        name="password"
                        required
                        min={6}
                        className="form-control log-input"
                        placeholder="password"
                        onChange={handleInputChange}
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          borderRadius: "8px",
                          border: "none",
                        }}
                      />
                      {!showPassword && (
                        <img
                          src={eyeClose}
                          alt=""
                          style={{ paddingRight: "4px", cursor: "pointer" }}
                          onClick={() => {
                            setShowPassword(true);
                          }}
                        />
                      )}
                      {showPassword && (
                        <img
                          src={eyeOpen}
                          width={28}
                          alt=""
                          style={{ paddingRight: "4px", cursor: "pointer" }}
                          onClick={() => {
                            setShowPassword(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <Button
                      id="submit"
                      className="btn form-sign-btn mt-4 mb-4 p-2 fw-bold"
                      type="submit"
                      onClick={handleAuthSubmit}
                    >
                      {isUserExist === null
                        ? "Submit"
                        : isUserExist === true
                        ? "Sign in"
                        : "Sign up"}
                    </Button>
                  </div>
                </>
              )}

              {otpMode && (
                <>
                  <div className="form-group mb-3">
                    <label htmlFor="phone_number" className="mt-2 mb-2">
                      Enter your Mobile Number
                    </label>
                    <input
                      type="tel"
                      name="phone_number"
                      required
                      minLength={10}
                      maxLength={10}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$"
                      className="form-control log-input"
                      placeholder="Mobile Number"
                      onChange={handleInputChange}
                    />
                  </div>
                  {otpSent && (
                    <div className="form-group mb-3">
                      <label htmlFor="otp" className="mt-2 mb-2">
                        Enter OTP
                      </label>
                      <input
                        type="text"
                        name="otp"
                        required
                        className="form-control log-input"
                        placeholder="Enter otp"
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                  {!otpSent && (
                    <div>
                      <Button
                        type="submit"
                        id="auth-button"
                        className="btn form-sign-btn mt-4 mb-4 p-2 fw-bold"
                        onClick={handleSendOtp}
                      >
                        Send OTP
                      </Button>
                    </div>
                  )}
                  {otpSent && (
                    <div>
                      <Button
                        className="btn form-sign-btn mt-4 mb-4 p-2 fw-bold"
                        type="submit"
                        onClick={handleAuthSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Auth;
