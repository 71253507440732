import CardDetails from "./CardDetails";
import CustomerDetails from "./CustomerDetails";
import OrderDetails from "./OrderDetails";
import PaymentDetails from "./PaymentDetails";
import ShipmentDetails from "./ShipmentDetails";

const list = [
  {
    label: "Order Details",
    id: "order_details",
    Component: OrderDetails,
  },
  {
    label: "Card Details",
    id: "card_details",
    Component: CardDetails,
  },
  {
    label: "Customer Details",
    id: "customer_details",
    Component: CustomerDetails,
  },
  {
    label: "Shipment Details",
    id: "shipment_details",
    Component: ShipmentDetails,
  },
  {
    label: "Payment Details",
    id: "payment_details",
    Component: PaymentDetails,
  },
];

export default list;
