import React, { useEffect, useState } from "react";
import QRCode from "qrcode";

const CardDetails = ({ orderData }) => {
  const [qrCodeUrl, setqrCodeUrl] = useState("");
  console.log("orderData ", orderData);

  useEffect(() => {
    var opts = {
      errorCorrectionLevel: "H",
      // type: "image/jpeg",
      type: "svg",
      quality: 0.3,
      margin: 1,
      color: {
        dark: "#000000",
        light: "#ffffff",
      },
    };

    QRCode.toDataURL(
      `${window.location.origin}/${
        orderData?.orderDetails?.card_detail?.name_uid || ""
      }`,
      opts,
      function (err, url) {
        if (err) throw err;
        var img = document.getElementById("qrCodeDiv");
        setqrCodeUrl(url);
        img.src = url;
      }
    );
  }, []);

  const downloadQRCode = () => {
    var element = document.createElement("a");
    element.href = qrCodeUrl;
    element.setAttribute("download", "");
    element.click();
  };

  const downloadImage = (url) => {
    var element = document.createElement("a");
    element.href = url;
    element.setAttribute("download", "");
    element.setAttribute("target", "_blank");
    element.click();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div class="row">
          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Card Id</label>
              <input
                type="text"
                name="name"
                required
                readOnly
                class="form-control"
                value={orderData?.orderDetails?.card?.id}
              />
            </div>
          </div>
          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Card Material</label>
              <input
                type="text"
                name="sub_title"
                value={orderData?.orderDetails?.card?.material_type}
                readOnly
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Card Amount</label>
              <input
                type="text"
                name="sub_title"
                value={orderData?.orderDetails?.card?.price}
                readOnly
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Name on Card</label>
              <input
                type="text"
                name="sub_title"
                value={orderData?.orderDetails?.card_detail?.name}
                readOnly
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Subtitle on Card</label>
              <input
                type="text"
                name="sub_title"
                value={orderData?.orderDetails?.card_detail?.sub_title}
                readOnly
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Company name on Card</label>
              <input
                type="text"
                name="sub_title"
                value={orderData?.orderDetails?.card_detail?.company_name}
                readOnly
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-8 fieldMb">
            <div class="form-group">
              <label>Card Design Image</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {
                  <img
                    src={orderData?.orderDetails?.card?.image_url}
                    alt="card design"
                    id="cardDesignImg"
                    width={400}
                    height={200}
                    style={{
                      border: "1px solid black",
                    }}
                  />
                }
                {orderData?.orderDetails?.card?.image_url && (
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => {
                      downloadImage(orderData?.orderDetails?.card?.image_url);
                    }}
                  >
                    Download design
                  </span>
                )}
              </div>
            </div>
          </div>

          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Card Logo</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {orderData?.orderDetails?.card_detail?.logo ? (
                  <img
                    src={orderData?.orderDetails?.card_detail?.logo}
                    alt="logo for the card"
                    id="cardLogoImg"
                    width={150}
                    height={150}
                    style={{
                      border: "1px solid black",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <div>No Logo uploaded</div>
                )}
                {orderData?.orderDetails?.card_detail?.logo && (
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => {
                      downloadImage(orderData?.orderDetails?.card_detail?.logo);
                    }}
                  >
                    Download logo
                  </span>
                )}
              </div>
            </div>
          </div>

          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>QR Code</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <img
                  alt="qr code for the card"
                  id="qrCodeDiv"
                  width={200}
                  height={200}
                />
                {qrCodeUrl && (
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={downloadQRCode}
                  >
                    Download QR
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
