import React, { useEffect, useState } from "react";
import OrderTableDetailsModal from "./OrderTableDetailsModal";
import request from "../../../../axios";
import { format, parseISO } from "date-fns";

const OrderTable = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const [orderId, setOrderId] = useState("");

  const [orderList, setOrderList] = useState([]);

  const getOrderList = () => {
    request
      .get(`/list_orders?filters[user_id]=${user?.id}`)
      .then((res) => {
        if (res?.data?.data?.list) {
          setOrderList(res.data.data.list);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleRowClick = (orderId) => {
    setOrderId(orderId);
    setShowModal(true);
  };

  const formatDate = (dateString) => {
    const parsedDate = parseISO(dateString);
    let date = format(parsedDate, "dd MMM yyyy");
    let time = format(parsedDate, "h: mm a");
    return { date, time };
  };

  return (
    <div>
      <div div class="table-responsive page-table mt-2">
        {orderList.length > 0 ? (
          <table class="table">
            <thead>
              <tr>
                <th>Order id</th>
                <th>Order amount</th>
                <th>Order status</th>
                <th>Payment status</th>
                <th>Order date</th>
              </tr>
            </thead>
            <tbody>
              {orderList.map((order) => {
                return (
                  <tr
                    class="tble-p orderAdminTable"
                    onClick={() => {
                      handleRowClick(order.id);
                    }}
                  >
                    <td>
                      <ul
                        class="list-inline d-flex"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <li class="list-inline-item">
                          <p class="mb-0">{order.id}</p>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <div>
                        <p class="mb-0">{order.order_amount}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="mb-0">{order.order_status}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="mb-0">{order.staus || "Payment Received"}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="mb-0">{formatDate(order.createdAt).date}</p>
                        <p class="mb-0">{formatDate(order.createdAt).time}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            No Orders Found
          </h2>
        )}
      </div>

      <OrderTableDetailsModal
        orderId={orderId}
        showModal={showModal}
        closeModal={closeModal}
      />
    </div>
  );
};

export default OrderTable;
