import React, { useContext, useEffect } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./component/Home";
import Auth from "./component/Auth";
import UserPreview from "./component/UserPreview";
import Dashboard from "./component/Dashboard";
import AdminDashboard from "./component/Admin/Dashboard";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/:user_name" element={<UserPreview />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
