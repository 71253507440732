import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./index.css";
import "./form-element.css";
import "./dashboard.css";
import UserContext from "./Context/UserContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardDetailsContext from "./Context/CardDetailsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserContext>
      <CardDetailsContext>
        <App />
        <ToastContainer closeOnClick={true} autoClose={5000} />
      </CardDetailsContext>
    </UserContext>
  </React.StrictMode>
);
reportWebVitals();
