import React, { useContext, useEffect, useState } from "react";
import call from "../../Images/call.svg";
import customerView from "../../Images/cust-view-bg.png";
import { useNavigate, useParams } from "react-router-dom";
import request from "../../axios";
import { handleNameFix } from "../../utils";
import VCard from "vcard-creator";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const UserPreview = () => {
  let { user_name } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [aboutYourSelf, setaboutYourSelf] = useState("");
  const navigate = useNavigate();
  
  
  useEffect(() => {
    if (user_name) {
      fetch(`${baseURL}/get_user_info?name=${user_name}`)
        .then((response) => {
          if (!response.ok) {
            navigate('/'); // Redirect to home page
            // throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          let userInfo = data?.data;
          if (userInfo) {
            let aboutIndex = userInfo?.handle_values.findIndex(
              (d) => d.handle_name === "about_yourself"
            );

            if (aboutIndex !== -1) {
              setaboutYourSelf(userInfo?.handle_values[aboutIndex]);
            }
            setUserDetails(userInfo);
          }
        })
        .catch((err) => {
          navigate('/');
        });
    }
  }, [user_name]);

  const generateVCFContent = (userDetails) => {
    const { name, handle_values } = userDetails;
    console.log(handle_values);
    // Basic vCard structure
    let vcf = `BEGIN:VCARD\nVERSION:3.0\nFN:${name}\n`;
  
    let whatsappNumbers = new Set(); // To store unique WhatsApp numbers
  
    handle_values.forEach((handle) => {
      switch (handle.account_id) {
        case "phone_number":
          // Add regular phone number
          vcf += `TEL;TYPE=WORK:${handle.account_value}\n`;
          // If it's also a WhatsApp number
          if (handle.is_whatsapp === 1) {
            whatsappNumbers.add(handle.account_value);
          }
          break;
  
        case "email_id":
          // Add email
          vcf += `EMAIL;TYPE=INTERNET:${handle.account_value}\n`;
          break;
  
        case "location":
          // Add location (assuming it’s a physical address)
          vcf += `ADR;TYPE=WORK:${handle.account_value}\n`;
          break;
  
        case "about_yourself":
          // Add "about yourself" section
          vcf += `NOTE:${handle.account_value}\n`;
          break;
  
        default:
          // Treat any other account_id ending with _account as a social media handle
          if (handle.account_id.endsWith("_account")) {
            const platform = handle.account_id.split("_")[0];
            vcf += `X-SOCIALPROFILE;TYPE=${platform}:${handle.account_value}\n`;
          }
          break;
      }
    });
  
    // Add all collected WhatsApp numbers
    whatsappNumbers.forEach((number) => {
      vcf += `TEL;TYPE=WHATSAPP:${number}\n`;
    });
  
    // Add whatsapp_number field separately if it exists
    const whatsappNumber = handle_values.find((handle) => handle.account_id === "whatsapp_number");
    if (whatsappNumber) {
      vcf += `TEL;TYPE=WHATSAPP:${whatsappNumber.account_value}\n`;
    }
  
    vcf += `END:VCARD\n`;
  
    return vcf;
  };
  

  const downloadVCFFile = () => {
    const vcfContent = generateVCFContent(userDetails);
    const blob = new Blob([vcfContent], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${userDetails.name || "contact"}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <div class="modal-body">
        <div>
          <div
            class="cust-view-bg"
            style={{ backgroundImage: `url(${customerView})` }}
          >
            {Object.keys(userDetails).length > 0 && (
              <>
                <section class="pt-8" style={{ margin: 0 }}>
                  <div class="container">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-5 col-lg-4 text-center brd-right">
                            <img
                              alt=""
                              src={userDetails.card_profile_url}
                              width={150}
                              height={150}
                              style={{
                                borderRadius: "50%",
                                border: "2px solid black",
                              }}
                            />
                            <p class="circle-usr mb-0">{userDetails.name}</p>
                            <p class="circle-usr-des">
                              {userDetails.sub_title},{" "}
                              {userDetails.company_name}
                            </p>
                          </div>
                          <div class="col-md-7 col-lg-8 usr-defini">
                            <p class="pt-2">{aboutYourSelf?.account_value} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="mt-4 sec-p">
                  <div class="container">
                    <div class="card">
                      <div class="card-body social-rows">
                        <div
                          class="row"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "start",
                          }}
                        >
                          {userDetails?.handle_values.map((handle) => {
                            return (
                              <>
                                {handle?.handle_name !== "about_yourself" && (
                                  <React.Fragment>
                                  <div class="col-4 col-md-2 col-lg-1 text-center">
                                    <a
                                      href={`${
                                        handle?.handle_name === "phone_number"
                                          ? "tel:"
                                          : ""
                                      }${
                                        handle?.handle_name === "email_id"
                                          ? "mailto:"
                                          : ""
                                      }${handle?.handle_name === "location"
                                         ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(handle?.account_value)}`
                                         :''
                                      }
                                      ${handle?.account_value}`}
                                      class="call-hover"
                                      target="_blank"
                                    >
                                      <img
                                        alt=""
                                        src={handle?.handle_icon || call}
                                        class="call-style"
                                      />
                                      <p class="pt-2">
                                        {handleNameFix(handle?.handle_name)}
                                      </p>
                                    </a>
                                  </div>
                                  {handle?.is_whatsapp == 1 &&
                                  <div class="col-4 col-md-2 col-lg-1 text-center">
                                  <a
                                    href={`https://wa.me/${handle?.account_value}`}
                                    class="call-hover"
                                    target="_blank"
                                  >
                                    <img
                                      alt=""
                                      src={'https://snaptap-logos.s3.ap-south-1.amazonaws.com/icons/whatsapp.svg' || call}
                                      class="call-style"
                                    />
                                    <p class="pt-2">
                                      Whatsapp 
                                    </p>
                                  </a>
                                </div>}
                                  </React.Fragment>
                                )}
                              </>
                            );
                          })}

                          {/* <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="##" class="call-hover">
                          <img alt="" src={call} class="call-style" />
                          <p class="pt-2">Call</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="email-hover">
                          <img alt="" src={email} class="email-style" />
                          <p class="pt-2">Email</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="location-hover">
                          <img alt="" src={location} class="location-style" />
                          <p class="pt-2">Location</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="website-hover">
                          <img alt="" src={website} class="website-style" />
                          <p class="pt-2">Website</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="message-hover">
                          <img alt="" src={message} class="message-style" />
                          <p class="pt-2">Message</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="facebook-hover">
                          <img alt="" src={facebook} class="facebook-style" />
                          <p class="pt-2">Facebook</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="instagram-hover">
                          <img alt="" src={instagram} class="instagram-style" />
                          <p class="pt-2">Instagram</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="twitter-hover">
                          <img alt="" src={twitter} class="twitter-style" />
                          <p class="pt-2">Twitter</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="linkedin-hover">
                          <img alt="" src={linkedin} class="linkedin-style" />
                          <p class="pt-2">Linkedin</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="google-hover">
                          <img alt="" src={google} class="google-style" />
                          <p class="pt-2">Google</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="youtube-hover">
                          <img alt="" src={youtube} class="youtube-style" />
                          <p class="pt-2">Youtube</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="telegram-hover">
                          <img alt="" src={telegram} class="telegram-style" />
                          <p class="pt-2">Telegram</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="whatsapp-hover">
                          <img alt="" src={whatsapp} class="whatsapp-style" />
                          <p class="pt-2">Whatsapp</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="messenger-hover">
                          <img alt="" src={messenger} class="messenger-style" />
                          <p class="pt-2">Messenger</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center">
                        <a href="# #" class="snapchat-hover">
                          <img alt="" src={snapchat} class="snapchat-style" />
                          <p class="pt-2">Snapchat</p>
                        </a>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center Call-hover dont-show">
                        <img alt="" src={call} class="call-style" />
                        <p class="pt-2">blank(do not use)</p>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center Call-hover dont-show">
                        <img alt="" src={call} class="call-style" />
                        <p class="pt-2">blank(do not use)</p>
                      </div>
                      <div class="col-4 col-md-2 col-lg-1 text-center Call-hover dont-show">
                        <img alt="" src={call} class="call-style" />
                        <p class="pt-2">blank(do not use)</p>
                      </div> */}
                          <div class="text-center social-sv-btn">
                            <button
                              class="btn"
                              type="button"
                              onClick={downloadVCFFile}
                            >
                              Save My Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPreview;
