import React, { useContext, useState } from "react";
import request from "../../../../../../axios";
import { toast } from "react-toastify";
import { UserContextProvider } from "../../../../../../Context/UserContext";

const ShipmentDetails = ({ orderData, setOrderData }) => {
  console.log("ORDER ", orderData);
  const [shipmentMethod, setShipmentMethod] = useState("");
  const { user, setUser } = useContext(UserContextProvider);

  const handleChangeShipmentMethod = (e) => {
    const value = e.target.value;
    setShipmentMethod(value);
  };

  const handleSaveShipmentMethod = () => {
    // call api

    let data = {
      order_id: orderData?.orderDetails?.id,
      shipping_method: shipmentMethod,
      user_id: user?.id,
    };

    request
      .post("/update_order", data)
      .then((res) => {
        setOrderData((prev) => {
          return {
            ...prev,
            orderDetails: {
              ...prev.orderDetails,
              shipping_method: shipmentMethod,
            },
          };
        });
        toast.success("Shipment method changed");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div class="row">
          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <div>
                <label htmlFor="shipmentMethod">Shipment method</label>
              </div>

              <select
                className="dropdown selectDropdown filled"
                name="shipmentMethod"
                value={
                  shipmentMethod || orderData?.orderDetails?.shipping_method
                }
                onChange={handleChangeShipmentMethod}
              >
                <option value="" disabled>
                  Select shipment method
                </option>
                <option value="UPS">UPS</option>
                <option value="DTDC">DTDC</option>
                <option value="BLUEDART">Blue Dart</option>
                <option value="GATI">Gati</option>
                <option value="DELHIVERY">Delhivery</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 fieldMb">
            <div class="form-group">
              <label>Shipping Address</label>
              <p id="edit">
                <b>
                  <span>{orderData?.address_detail?.name}</span>
                  <br />
                  <span>{orderData?.address_detail?.flat}</span>
                  <br />
                  <span>
                    {orderData?.address_detail?.area}{" "}
                    {orderData?.address_detail?.landmark}
                  </span>
                  <br />
                  <span>
                    {orderData?.address_detail?.city}{" "}
                    {orderData?.address_detail?.state}{" "}
                    {orderData?.address_detail?.pincode}
                  </span>
                  <br />
                  <span>{orderData?.address_detail?.phone_number}</span>
                </b>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button
            disabled={shipmentMethod === ""}
            type="submit"
            class="btn btn-next btns"
            onClick={handleSaveShipmentMethod}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetails;
