const updateLocalStorage = (userid) => {
  let id = btoa(userid);
  let time = btoa(Date.now() + 30 * 24 * 60 * 1000);
  localStorage.setItem("snaptapId", `${id} ${time}`);
};

const handleNameFix = (handleName) => {
  let names = handleName.split("_");

  let a = "";
  let b = "";

  if (names.length >= 2) {
    a = names[0][0].toUpperCase() + names[0].substring(1, names[0].length);
    b = names[1][0].toUpperCase() + names[1].substring(1, names[1].length);
  } else {
    a = names[0][0].toUpperCase() + names[0].substring(1, names[0].length);
  }

  return a + " " + b;
};

export { updateLocalStorage, handleNameFix };
