import React, { createContext, useState } from "react";

const CardDetailsProvider = createContext(null);
const CardDetailsContext = ({ children }) => {
  const [cardDetails, setCardDetails] = useState({
    design: {
      card_type: "PVC",
      card_id: null,
    },
    customize: {
      name: "",
      sub_title: "",
      company_name: "",
      logo: "",
    },
    handles: [],
  });
  return (
    <CardDetailsProvider.Provider value={{ cardDetails, setCardDetails }}>
      {children}
    </CardDetailsProvider.Provider>
  );
};

export { CardDetailsProvider };
export default CardDetailsContext;
