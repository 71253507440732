import React, { useContext, useState } from "react";
import request from "../../../axios";
import { UserContextProvider } from "../../../Context/UserContext";
import { toast } from "react-toastify";

const AccountDetails = () => {
  const { user, setUser } = useContext(UserContextProvider);

  console.log(user);

  const [editMode, setEditMode] = useState(false);

  const [passwords, setPasswords] = useState({
    new_password: "",
    confirm_new_password: "",
    old_password: "",
  });

  const onInputPassword = (e) => {
    const { name, value } = e.target;
    setPasswords((pass) => {
      return {
        ...pass,
        [name]: value,
      };
    });
  };

  const handlePasswordChange = async (e) => {
    if (passwords.new_password !== passwords.confirm_new_password) {
      toast.error("Confirm password is not matching with new password");
      return;
    }
    if (
      passwords.old_password &&
      passwords.new_password &&
      passwords.confirm_new_password
    ) {
      await request
        .post("/update_password", {
          old_password: passwords.old_password,
          new_password: passwords.new_password,
          user_id: user.id,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Password updated successfully");
          }
          setEditMode(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="col-md-9 full-sec">
      <div class="card accountCard mt-3  ">
        <div class="card-header p-3 ">
          <ul class="list-inline address-list mb-0">
            <li class="list-inline-item">
              <p class="mt-3">Change Password</p>
            </li>
            <li class="list-inline-item">
              <button
                class="btn tab1-btn edit-button"
                type="button"
                onClick={() => {
                  setEditMode(true);
                }}
                disabled={user.isOauth ? true : false}
              >
                <i class="fa fa-edit" aria-hidden="true"></i>&nbsp;{" "}
                <span>Edit</span>
              </button>
            </li>
          </ul>
        </div>
        {editMode && (
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div class="row">
                    <div class="col-md-12 pb-3">
                      <div class="form-group">
                        <label htmlFor="old_password">Old Password</label>
                        <input
                          type="password"
                          required
                          name="old_password"
                          placeholder="Enter current password"
                          minLength={8}
                          class="form-control input"
                          onChange={onInputPassword}
                        />
                      </div>
                    </div>
                    <div class="col-md-12 pb-3">
                      <div class="form-group">
                        <label htmlFor="new_password">New Password</label>
                        <input
                          type="password"
                          required
                          name="new_password"
                          minLength={8}
                          placeholder="Enter new password"
                          class="form-control input"
                          onChange={onInputPassword}
                        />
                      </div>
                    </div>
                    <div class="col-md-12 pb-3">
                      <div class="form-group">
                        <label htmlFor="confirm_new_password">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          required
                          name="confirm_new_password"
                          minLength={8}
                          placeholder="confirm new password"
                          class="form-control input"
                          onChange={onInputPassword}
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <button
                          class="btn tab1-btn apply-btn"
                          type="submit"
                          onClick={handlePasswordChange}
                        >
                          Apply Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountDetails;
