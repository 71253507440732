import React, { useContext, useEffect, useState } from "react";
import request from "../../../axios";
import { UserContextProvider } from "../../../Context/UserContext";

const Order = () => {
  const { user, setUser } = useContext(UserContextProvider);
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    if (user) {
      request
        .get(`/list_orders?filters[user_id]=${user?.id}`)
        .then((res) => {
          if (res?.data?.data?.list) {
            setOrderList(res.data.data.list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  return (
    <div className="col-md-9 full-sec">
      <div>
        <p class="mb-0 pg-hd">My Orders</p>
        {orderList.length > 0 && <small class="small-txt">4 Orders</small>}
        <div class="table-responsive page-table mt-2">
          {orderList.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Card Type</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orderList.map((order) => {
                  return (
                    <tr class="tble-p">
                      <td>
                        <ul
                          class="list-inline d-flex"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <li class="list-inline-item">
                            <img
                              src={order.card_detail.logo || ""}
                              alt=""
                              width={40}
                              height={40}
                            />
                          </li>
                          <li class="list-inline-item">
                            <p class="mb-0">{order.card_detail.name}</p>
                            <small class="table-small">
                              {order.card_detail.company_name}
                            </small>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div>
                          <p class="mb-0">{order?.card?.material_type}</p>
                          <small class="table-small">{order.card.color}</small>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p class="mb-0">{order.card.price}</p>
                          <small class="table-small">
                            {order.razorpay_order_id ? "Received" : "Pending"}
                          </small>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p class="mb-0">{order.order_status}</p>
                          <small class="table-small">{order.status_desc}</small>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h2
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              No Orders Found
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default Order;
