import React from "react";
import section51 from "../../../Images/section51.png";
import check from "../../../Images/check.png";
import { Link } from "react-router-dom";

const Section51 = () => {
  return (
    <div
      className="section51_1"
      style={{
        background: "#F5F6F8",
        padding: "30px 30px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "100px",
        }}
        className="section51"
      >
        <div>
          <img
            className="section51_img"
            src={section51}
            alt="google fnc card"
            width={400}
            height={500}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "30px",
            gap: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "70px",
              }}
            >
              Google Review{" "}
              <span
                style={{
                  color: "#02B584",
                }}
              >
                Card NFC
              </span>
            </h2>
            <p>Step up your Google review</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                width: "65%",
              }}
              className="section51_pointer"
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={check} alt="check" width={18} height={13} />
                    <span>Efficient Google Tap Feedback Procedure</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={check} alt="check" />

                    <span>Effective tool to boosts reviews</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={check} alt="check" />

                    <span>QR NFC Card: Simple Tap for Reviews</span>
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={check} alt="check" />

                    <span>Compact and Aesthetic Card Design</span>
                  </div>{" "}
                </div>

                <div
                  style={{
                    marginTop: "60px",
                  }}
                >
                  <Link to="/auth">
                    <button
                      type="button"
                      className="btn "
                      style={{ background: "black", color: "white" }}
                    >
                      Try now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section51;
