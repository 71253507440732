import React, { useEffect, useState } from "react";
import request from "../../../../axios";
import { toast } from "react-toastify";

const Modal = ({
  showModal,
  setShowModal,
  modalAddress = {},
  closeModal,
  setRefectchAddress,
  user = {},
}) => {
  const {
    name = "",
    flat = "",
    area = "",
    city = "",
    state = "",
    pincode = "",
    landmark = "",
    phone_number = "",
  } = modalAddress;

  const [fieldDetails, setFieldDetails] = useState({
    name: "",
    phone_number: "",
    flat: "",
    area: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });

  useEffect(() => {
    setFieldDetails({
      name: name,
      phone_number: phone_number,
      flat: flat,
      area: area,
      landmark: landmark,
      city: city,
      state: state,
      pincode: pincode,
    });
  }, [modalAddress]);

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number" && `${value}`.length > 10) {
      return;
    }
    setFieldDetails((fields) => {
      return {
        ...fields,
        [name]: value,
      };
    });
  };

  const handleAddressSave = () => {
    let data = { ...fieldDetails };

    let path = "/create_address";
    if (Object.keys(modalAddress).length > 0) {
      path = "/update_address";
      data = { ...data, id: modalAddress.id };
    }

    data.user_id = user.id;
    data.country = "India";

    if (data.name && data.pincode.length !== 6) {
      toast.error("Pincode must be of 6 digit");
      return;
    }

    if (
      data.name &&
      data.phone_number &&
      data.city &&
      data.state &&
      data.pincode &&
      data.country
    ) {
      request
        .post(path, data)
        .then((res) => {
          if (res.status === 200) {
            if (Object.keys(modalAddress).length > 0) {
              toast.success("Address updated successfully");
            } else {
              toast.success("Address added successfully");
            }
          }
          setRefectchAddress(true);
          closeModal();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchPincodeInfo = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      let data = await response.json();
      data = data[0];

      if (data["Status"] === "Success") {
        const state = data.PostOffice[0]?.State || "";
        const city = data.PostOffice[0]?.Division || "";

        setFieldDetails((fields) => {
          return {
            ...fields,
            pincode: pincode,
            state,
            city,
          };
        });
      } else {
        toast.error("Invalid pincode");
      }
    } catch (error) {
      toast.error("Error fetching pincode information");
    }
  };

  const onPinCodeChange = (e) => {
    let value = e.target.value;
    setFieldDetails((fields) => {
      return {
        ...fields,
        pincode: value,
      };
    });
    if (value.length === 6) {
      fetchPincodeInfo(value);
    } else {
      handleValueChange(e);
    }
  };

  return (
    <div>
      {showModal && (
        <div className="addressAdd">
          <div className="modelContent">
            <div className="modelContent1">
              <div class="modal-header">
                <h5 class="modal-title">Address</h5>
                <div style={{ cursor: "pointer" }} onClick={closeModal}>
                  <span>X</span>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          placeholder="Name"
                          pattern="[A-Za-z ]+"
                          minlength={6}
                          required
                          class="form-control input"
                          name="name"
                          value={fieldDetails.name}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label htmlFor="phone_number">Mobile Number</label>
                        <input
                          type="number"
                          placeholder="Mobile Number"
                          minLength={10}
                          maxLength={10}
                          required
                          class="form-control input"
                          name="phone_number"
                          value={fieldDetails.phone_number}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label htmlFor="flat">Flat, House no., Building</label>
                        <input
                          type="text"
                          placeholder="Flat, House no., Building"
                          class="form-control input"
                          name="flat"
                          value={fieldDetails.flat}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label htmlFor="area">Area, Street, Sector</label>
                        <input
                          type="text"
                          placeholder="Area, Street, Sector"
                          class="form-control input"
                          name="area"
                          value={fieldDetails.area}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label htmlFor="landmark">Landmark</label>
                        <input
                          type="text"
                          placeholder="Landmark"
                          class="form-control input"
                          name="landmark"
                          value={fieldDetails.landmark}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label htmlFor="city">Town/City</label>
                        <input
                          type="text"
                          placeholder="Town/City"
                          class="form-control input"
                          required
                          name="city"
                          value={fieldDetails.city}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label htmlFor="state">State</label>
                        <input
                          type="text"
                          placeholder="State"
                          required
                          class="form-control input"
                          name="state"
                          value={fieldDetails.state}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label htmlFor="pincode">Pincode</label>
                        <input
                          type="text"
                          placeholder="Pincode"
                          pattern="[0-9]{6}"
                          minLength={6}
                          maxLength={6}
                          required
                          class="form-control input"
                          name="pincode"
                          value={fieldDetails.pincode}
                          onChange={onPinCodeChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer text-center">
                  <div class="popup-btn">
                    <button
                      class="btn theme-btn"
                      type="submit"
                      onClick={handleAddressSave}
                    >
                      Save Address
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
