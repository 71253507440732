import React from "react";

const CustomerDetails = ({ orderData }) => {
  return (
    <div>
      <div class="row">
        <div class="col-md-4 fieldMb">
          <div class="form-group">
            <label>Customer id</label>
            <input
              type="text"
              name="name"
              value={orderData?.orderDetails?.user?.id}
              readOnly
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-4 fieldMb">
          <div class="form-group">
            <label>Customer Email</label>
            <input
              type="text"
              name="sub_title"
              value={orderData?.orderDetails?.user?.email}
              readOnly
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-4 fieldMb">
          <div class="form-group">
            <label>Customer number</label>
            <input
              type="text"
              name="company_name"
              value={orderData?.orderDetails?.user?.phone_number}
              readOnly
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
