import axios from "axios";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const request = axios.create({
  baseURL: baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    let errMsg = err?.response?.data?.message || "";
    if (errMsg) {
      toast.error(errMsg);
    } else {
      toast.error("Internal Server error! Please try again after sometime");
    }
  }
);

export default request;
