import React, { useEffect, useState } from "react";
import accordionList from "./accordionList";
import request from "../../../../../axios";
const OrderTableDetailsModal = ({
  showModal = false,
  closeModal = () => {},
  orderId = "",
}) => {
  const [selectedAccordion, setSelectedAccordion] = useState("order_details");

  const [orderData, setOrderData] = useState({});

  const getOrderByOrderId = (orderId = "") => {
    request
      .get(`/get_order?order_id=${orderId}`)
      .then((res) => {
        if (res?.data?.data) {
          setOrderData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (orderId !== "") {
      // call get order details by orderId
      getOrderByOrderId(orderId);
    }
  }, [orderId]);

  const handleAccordionClick = (id) => {
    if (id === selectedAccordion) {
      setSelectedAccordion("");
    } else {
      setSelectedAccordion(id);
    }
  };

  return (
    <div>
      {showModal && (
        <div className="addressAdd">
          <div className="modelContent model-content">
            <div
              className="modelContent1"
              style={{ width: "90%", overflowY: "scroll" }}
            >
              <div class="modal-header">
                <h5 class="modal-title">Order : {orderData?.id}</h5>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <span>X</span>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div class="modal-body">
                  <div id="appenddiv">
                    <div
                      class="row add-para"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {accordionList.map((list) => {
                        let Component = list.Component;
                        return (
                          <div
                            style={{
                              width: "95%",
                              marginBottom: "20px",
                            }}
                          >
                            <div
                              id={list.id}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "50px",
                                background: "#D3D3D3",
                                cursor: "pointer",
                                padding: "0 10px",
                              }}
                              onClick={() => {
                                handleAccordionClick(list.id);
                              }}
                            >
                              <div>{list.label}</div>
                              <div
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "500",
                                }}
                              >
                                {selectedAccordion === list.id ? "-" : "+"}
                              </div>
                            </div>
                            {selectedAccordion === list.id && (
                              <div
                                style={{
                                  padding: "20px",
                                }}
                              >
                                <Component
                                  orderData={orderData}
                                  setOrderData={setOrderData}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderTableDetailsModal;
