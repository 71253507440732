import React from "react";
import section52 from "../../../Images/section52.png";
import { Link } from "react-router-dom";

const Section52 = () => {
  return (
    <div
      className="dashboard-bg section52"
      style={{
        backgroundImage: `url(${section52})`,
        height: "305px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        gap: "100px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "60px",
              lineHeight: "54px",
              fontWeight: "600",
            }}
            className="section52_head"
          >
            Bulk Order
          </div>
        </div>
        <div>
          <span
            style={{
              color: "#1458E9",
              fontSize: "30px",
              fontWeight: "400",
            }}
            className="section52_subhead"
          >
            Start Saving with Bulk Orders Today
          </span>
        </div>
      </div>
      <div>
        <Link to="https://wa.me/7982360446" target="_blank">
          <button
            type="button"
            className="btn"
            style={{
              background: "black",
              color: "white",
            }}
          >
            Contact us
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Section52;
