import React, { useContext, useEffect } from "react";
import { UserContextProvider } from "../../../Context/UserContext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import OrderTable from "./OrderTable";
import request from "../../../axios";
import { updateLocalStorage } from "../../../utils";
import Logo from "../.././../Images/Logo.svg";
import { toast } from "react-toastify";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContextProvider);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const isCallback = searchParams.get("callback");
    const callbackuserId = searchParams.get("userId");
    let localStorageDetail = localStorage.getItem("snaptapId");

    if (isCallback && callbackuserId) {
      request
        .get(`/get_user?id=${atob(callbackuserId)}`)
        .then((res) => {
          if (res?.data?.user) {
            if (!res?.data?.user?.is_admin) {
              navigate("/dashboard");
            }
            updateLocalStorage(atob(callbackuserId));
            setUser({ ...res.data.user, isOauth: true });
            setSearchParams("");
          }
        })
        .catch((err) => {});
    } else {
      if (localStorageDetail) {
        localStorageDetail = localStorageDetail.split(" ");
        let currentTime = Date.now();
        let userId = localStorageDetail[0];
        let storedTime = localStorageDetail[1];
        storedTime = atob(storedTime);
        if (storedTime - currentTime <= 0) {
          localStorage.removeItem("snaptapId");
          // navigate("/auth");
          // window.location.href = "/auth";
          navigate("/auth");
        } else {
          request
            .get(`/get_user?id=${atob(userId)}`)
            .then((res) => {
              let user = res?.data?.user;
              if (user) {
                if (!user?.is_admin) {
                  navigate("/dashboard");
                } else {
                  setUser(res.data.user);
                }
              }
            })
            .catch((err) => {
              navigate("/auth");
            });
        }
      } else {
        navigate("/auth");
      }
    }
  }, []);

  const handleLogOut = () => {
    // conditions
    localStorage.removeItem("snaptapId");
    setUser({});
    toast.success("Log out successfully");
    navigate("/auth");
  };

  return (
    <>
      {user?.is_admin && (
        <div
          style={{
            display: "flex",
            gap: "50px",
            flexDirection: "column",
            margin: "30px 50px 0 50px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
            <div
              onClick={handleLogOut}
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100px",
              }}
            >
              <div>
                <button
                  type="button"
                  className="btn form-control theme-btn desk-logout-btn"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h2>Admin Page</h2>
          </div>

          <div>
            <div
              style={{
                marginBottom: "30px",
              }}
            >
              <h4>Order Details</h4>
            </div>
            <OrderTable user={user} />
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
