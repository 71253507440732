import React from "react";

const AboutUs = () => {
  return (
    <div id="about_us">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          padding: "0px 80px",
          marginTop: "32px",
        }}
      >
        <h1
          style={{
            fontWeight: 800,
            textAlign: "center",
          }}
        >
          About Us
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <p>
            Welcome to SnapTap, a pioneering technology company that is
            revolutionizing the way we interact with each other. We are
            dedicated to providing innovative solutions that bridge the gap
            between the physical and digital worlds.
          </p>
          <h3>Our Story</h3>
          <p>
            At SnapTap, we believe that technology should be accessible and
            intuitive. Our team of experts has come together to create a unique
            product that combines the power of NFC technology with the
            convenience of a contact card. Our NFC-enabled contact card is
            designed to make it easy for individuals and businesses to share
            information, connect with others, and access online resources.
          </p>
          <h3>What We Do</h3>
          <p>
            Our NFC-enabled contact card is a simple and effective way to open a
            link on a browser when tapped on a phone. Whether you're looking to
            share a business card, promote a product or service, or connect with
            customers, our card is the perfect solution.
            <br />
            You can also scan the QR code to open the link, You will have your
            own unique profile to browse if person is not available physically.
          </p>
          <h3>Our Mission</h3>
          <p>
            Our mission is to empower individuals and businesses to connect with
            each other in new and innovative ways. We believe that by making
            technology more accessible and user-friendly, we can create a more
            connected and collaborative world.
          </p>
          <h3>Get in Touch</h3>
          <p>
            If you're interested in learning more about our NFC-enabled contact
            cards or would like to place an order, please don't hesitate to
            contact us. We'd be happy to answer any questions you may have.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
