import greyCard from "../../Images/grey-card.svg";
import blueCard from "../../Images/blue-card.svg";
import greyBag from "../../Images/grey-bag.svg";
import blueBag from "../../Images/blue-bag.svg";
import greyLocation from "../../Images/grey-location.svg";
import blueLocation from "../../Images/blue-location.svg";
import greyAccount from "../../Images/grey-account.svg";
import blueAccount from "../../Images/blue-account.svg";

const dashboardList = [
  {
    label: "My Cards",
    greyIcon: greyCard,
    blueIcon: blueCard,
    name: "myCards",
  },
  {
    label: "My Orders",
    greyIcon: greyBag,
    blueIcon: blueBag,
    name: "myOrders",
  },
  {
    label: "My Addresses",
    greyIcon: greyLocation,
    blueIcon: blueLocation,
    name: "myAddress",
  },
  {
    label: "Account Details",
    greyIcon: greyAccount,
    blueIcon: blueAccount,
    name: "accountDetails",
  },
];
export default dashboardList;
