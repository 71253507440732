import React, { createContext, useState } from "react";

const UserContextProvider = createContext(null);
const UserContext = ({ children }) => {
  const [user, setUser] = useState({});
  return (
    <UserContextProvider.Provider value={{ user, setUser }}>
      {children}
    </UserContextProvider.Provider>
  );
};

export { UserContextProvider };
export default UserContext;
